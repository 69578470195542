import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import HandlerFilter from '../../../classes/Filter';

import getFormatPrice from '../../../functions/getFormatPrice';
import setSpacesInText from '../../../functions/setSpacesInText';

import Table from '../../../components/crm/manual/Table.jsx';
import WidgetFilter from '../../../components/crm/widget/Filter.jsx';

import Loader from '../../../components/Loader.jsx';
import Animate from '../../../components/Animate.jsx';

import AnimateChangeUp from '../../../components/AnimateChangeUp.jsx';
import ListScroll from '../../../components/ListScroll.jsx';
import ListAbsoluteMain from '../../../components/ListAbsoluteMain.jsx';
import getHeaders from '../../../functions/getHeaders';
import getFormatedDate from '../../../functions/getFormatedDate';
import CorporationInfo from '../../../components/crm/manual/CorporationInfo.jsx';
import getFilter from '../../../requests/getFilter';
import Icon from '../../../components/Icon.jsx';
import handlerPopup from '../../../functions/app/handlerPopup';

class SettingsBalance extends Table {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderItem = this.renderItem.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
    }

    classNameItem = '.settingsUsersTable__row';

    stepCounter = 25;

    renderItem({ item, prop: id }) {
        if (id === 'filterInfo') {
            return (
                <div className="settingsUsersTable__row _info">
                    <div className="empty _col _block _notBack">
                        <div className="empty__inner">
                            <div className="empty__title">По вашему запросу ничего не найдено</div>
                            <p className="empty__content">Попробуйте изменить критерии фильтра</p>
                        </div>
                    </div>
                </div>
            );
        }

        if (id === 'emptyInfo') {
            return (
                <div className="settingsUsersTable__row _info">
                    <div className="empty _col _block _notBack">
                        <div className="empty__inner">
                            <div className="empty__title">Логов пока нет</div>
                            <p className="empty__content">
                                Они будут отображаться здесь в виде карточек
                            </p>
                        </div>
                    </div>
                </div>
            );
        }

        const { user } = this.state;

        return (
            <div className="settingsUsersTable__row">
                <div className="settingsUsersTable__rowInner _row">
                    <div className="settingsUsersTable__col _date">
                        <div
                            className="settingsUsersTable__colInner _fontMedium _grey"
                            dangerouslySetInnerHTML={{
                                __html: setSpacesInText(
                                    getFormatedDate({
                                        date: new Date(item.createdDate),
                                        type: 'full',
                                        withSeconds: true,
                                    }),
                                ),
                            }}
                        ></div>
                    </div>
                    {user?.idOfCurrentCorporation === 'admin' && (
                        <div className="settingsUsersTable__col _corporationInfo">
                            <div className="settingsUsersTable__colInner">
                                <CorporationInfo {...item.corporationInfo?.[0]} />
                            </div>
                        </div>
                    )}
                    <div className="settingsUsersTable__col _name">
                        <div
                            className="settingsUsersTable__colInner"
                            dangerouslySetInnerHTML={{
                                __html: setSpacesInText(item.transactionDescription),
                            }}
                        ></div>
                    </div>
                    <div className="settingsUsersTable__col _amount">
                        <div
                            className={`settingsUsersTable__colInner _fontMedium ${
                                item.amount > 0 ? '_success' : '_alert'
                            }`}
                        >
                            {item.amount > 0 ? '+' : '-'}
                            {getFormatPrice(Math.abs(item.amount))} ₽
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    getItems() {
        const { user } = this.state;
        const { inner } = this.props;
        const query = this.getQueryForRequest();

        let queryStr = '';

        if (inner) {
            query.params.push({ key: 'adminCorporationId', value: user.idOfCurrentCorporation });
        }

        query.params.forEach((param) => {
            queryStr += `${param.key}=${param.value}&`;
        });

        queryStr += 'type=balance';

        return new Promise((resolve) => {
            axios
                .get(`${process.env.REACT_APP_API}/corporation?${queryStr}`, {
                    headers: getHeaders(),
                })
                .then((res) => {
                    const { success, data } = res.data;

                    if (success) {
                        const { balances, isLimit, counter } = data;

                        this.setItems(balances, false, isLimit, counter).then(() => {
                            resolve();
                        });
                    }
                });
        });
    }

    setFilter(filter) {
        this.setState({ filter });
    }

    getFilter() {
        const { inner } = this.props;

        getFilter({ name: 'corporationBalance' }).then(
            ({ blocks }) => {
                if (inner) {
                    const corporationBlockIndex = blocks.findIndex(
                        (block) => block.key === 'corporations',
                    );

                    if (corporationBlockIndex !== -1) {
                        blocks.splice(corporationBlockIndex, 1);
                    }
                }

                this.initFilter({ blocks });
            },
            () => null,
        );
    }

    initFilter({ blocks = [] }) {
        this.handlerFilter.init({ blocks });
    }

    handlerExport() {
        const { counter } = this.state;
        const query = this.getQueryForRequest();

        if (counter > 0) {
            handlerPopup({
                name: 'exportPopup',
                isShow: true,
                counter,
                filterQuery: query.params,
                type: 'operations',
            });
        }
    }

    componentDidMount() {
        const { user, setHeightPage, inner, initCallbackFilter } = this.props;

        setHeightPage();

        this.getFilter();

        if (initCallbackFilter) {
            initCallbackFilter(this.handlerUpdateFilter);
        }

        this.handlerFilter = new HandlerFilter({
            context: this,
        });

        this.setState(
            {
                user: inner
                    ? {
                          ...JSON.parse(JSON.stringify(user)),
                          idOfCurrentCorporation: this.props.corporation?._id,
                      }
                    : user,
            },
            () => {
                this.getItems(true);
            },
        );
    }

    render() {
        const {
            user,
            filter,
            isReady,
            isLoadingFilter = false,
            counter = 0,
            items = [],
            isLimit,
            isShowLoaderList,
            counterUpdate,
        } = this.state;
        const { getParent } = this.props;

        return (
            <div ref={this.parent} className="settingsBalance">
                <div className="settingsBalancet__inner">
                    <div className="settingsBalance__content">
                        <div
                            className={`settingsUsersTable _corporationBalance _full _init ${
                                user?.idOfCurrentCorporation === 'admin' ? '_adminCorporation' : ''
                            } ${!isReady || isLoadingFilter ? '_loading' : ''}`}
                        >
                            <div className="settingsUsersTable__inner">
                                <div className="settingsUsersTable__wrapper">
                                    <div className="settingsUsersTable__head _static">
                                        <div className="settingsUsersTable__headInner _row">
                                            <div className="settingsUsersTable__headTitle _row">
                                                Операции:{' '}
                                                <AnimateChangeUp
                                                    className="settingsUsersTable__headTitleItem"
                                                    renderKey={counter}
                                                >
                                                    ({counter})
                                                </AnimateChangeUp>
                                            </div>
                                            <div className="settingsUsersTable__headActions _row">
                                                <div
                                                    className="widget__headAction settingsUsersTable__headPaysButton _grey"
                                                    onClick={this.handlerExport.bind(this)}
                                                >
                                                    <div className="widget__headActionInner _col _click">
                                                        <i className="widget__headActionIcon">
                                                            <Icon name="widget-export" />
                                                        </i>
                                                    </div>
                                                </div>
                                                <div className="widget__headAction settingsUsersTable__headPaysButton">
                                                    <div className="settingsUsersTable__headFilter">
                                                        <WidgetFilter
                                                            className="_grey"
                                                            name="filter"
                                                            filter={filter}
                                                            getParent={getParent}
                                                            handlerFilter={this.handlerFilter}
                                                            // windowName="logsFilter"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Animate
                                        className="settingsUsersTable__loader _loader"
                                        isShow={!isReady || isLoadingFilter}
                                    >
                                        <i className="settingsUsersTable__loaderItem _loaderItem">
                                            <Loader className="_main" />
                                        </i>
                                    </Animate>
                                    <Animate
                                        className="settingsUsersTable__empty"
                                        isShow={items.length === 0 && !isLoadingFilter && isReady}
                                    >
                                        <div className="empty _col _notBack _block">
                                            <div className="empty__inner">
                                                <div className="empty__title">
                                                    На данный момент операций нет
                                                </div>
                                                <p className="empty__content">
                                                    Они будут отображаться здесь в виде строк
                                                </p>
                                            </div>
                                        </div>
                                    </Animate>
                                    <div className={`settingsUsersTable__content JSTransVertical`}>
                                        <div className="settingsUsersTable__row _head">
                                            <div className="settingsUsersTable__col _date">
                                                Дата
                                            </div>
                                            {user?.idOfCurrentCorporation === 'admin' && (
                                                <div className="settingsUsersTable__col _corporationInfo">
                                                    Корпорация
                                                </div>
                                            )}
                                            <div className="settingsUsersTable__col _name">
                                                Назначение платежа
                                            </div>
                                            <div className="settingsUsersTable__col _amount">
                                                Сумма
                                            </div>
                                        </div>
                                        <div
                                            className="settingsUsersTable__contentInner"
                                            key={counterUpdate}
                                        >
                                            {isReady && (
                                                <ListScroll
                                                    isInit={isReady}
                                                    getParent={() =>
                                                        this.parent.current?.querySelector(
                                                            '.settingsUsersTable__content',
                                                        )
                                                    }
                                                    callback={this.getMoreItems}
                                                    startCounter={this.stepCounter}
                                                    stepCounter={this.stepCounter}
                                                    maxCounter={Infinity}
                                                    lengthCurrent={items.length}
                                                    handlerLoaderList={this.handlerLoaderList}
                                                    isLimit={isLimit}
                                                    isDisabledScroll={isLoadingFilter}
                                                >
                                                    <ListAbsoluteMain
                                                        classNames={[
                                                            '_corporationBalance',
                                                            ...(user?.idOfCurrentCorporation ===
                                                            'admin'
                                                                ? ['_adminCorporation']
                                                                : []),
                                                        ]}
                                                        className="settingsUsersTable__rows _col _dynamic"
                                                        items={items}
                                                        renderItem={this.renderItem}
                                                        classNameItem="settingsUsersTable__row"
                                                        prop="_id"
                                                        paramsParent={{ width: true }}
                                                        styles={['height']}
                                                    />
                                                </ListScroll>
                                            )}
                                        </div>
                                    </div>
                                    <Animate
                                        className="settingsUsersTable__listLoader _loaderScroll _white"
                                        isShow={isShowLoaderList}
                                    >
                                        <div className="settingsUsersTable__listLoaderItem _loaderItem">
                                            <Loader className="_main" />
                                        </div>
                                    </Animate>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(SettingsBalance);

SettingsBalance.propTypes = {
    corporation: PropTypes.object,
    getParent: PropTypes.func,
    initCallbackFilter: PropTypes.func,
    getTariffString: PropTypes.func,
    setHeightPage: PropTypes.func,
    user: PropTypes.object,
    inner: PropTypes.bool,
};
