import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import File from '../../../../classes/File';

import getArticles from '../../../../requests/getArticles';

import BlogMain from './inner/Main.jsx';
import InfoMain from '../info/inner/Main.jsx';
import Content from './inner/Content.jsx';

import Pages from '../../../../components/Pages.jsx';
import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';

import getHeaders from '../../../../functions/getHeaders';

import Inner from '../../../../components/crm/manual/Inner.jsx';
import setNotification from '../../../../functions/setNotification';
import changePage from '../../../../functions/changePage';
import handlerErrorRequest from '../../../../functions/handlerErrorRequest';
import { dispatcher } from '../../../../redux/redux';
import getPageLink from '../../../../functions/getPageLink';

class ContentBlogInner extends Inner {
    constructor(props) {
        super(props);
        this.state = {
            counterUpdateOrderBlocks: 0,
            newFiles: {},
        };

        this.changeArticle = this.changeArticle.bind(this);
        this.uploadMainFile = this.uploadMainFile.bind(this);
        this.getFormData = this.getFormData.bind(this);
        this.clearFiles = this.clearFiles.bind(this);
        this.getArticle = this.getArticle.bind(this);

        this.createArticle = this.createArticle.bind(this);
        this.publicateArticle = this.publicateArticle.bind(this);

        this.parent = React.createRef();
    }

    mainPage = this.props.name === 'info' ? 'content-info' : 'content-blog';

    targetName = 'article';

    targetNameSave = 'articleSave';

    pages = {
        [`content-${this.props.name}-inner-main`]: {
            render() {
                const { article, articleSave, newFiles } = this.state;
                const { checkNew, checkRights, name } = this.props;
                const Main = name === 'info' ? InfoMain : BlogMain;

                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner">
                                <Main
                                    article={article}
                                    articleSave={articleSave}
                                    isNew={checkNew()}
                                    changeArticle={this.changeArticle}
                                    backToSave={this.backToSave}
                                    uploadMainFile={this.uploadMainFile}
                                    getFormData={this.getFormData}
                                    clearFiles={this.clearFiles}
                                    checkRights={checkRights}
                                    newFiles={newFiles}
                                    getArticle={this.getArticle}
                                    name={name}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
        [`content-${this.props.name}-inner-content`]: {
            render() {
                const { article, newFiles } = this.state;
                const { checkNew, getParent, checkRights, name } = this.props;

                return (
                    <>
                        <div className="widget__pageBox _scroll">
                            <div className="widget__pageInner _notPadding">
                                <Content
                                    name={name}
                                    article={article}
                                    isNew={checkNew()}
                                    changeArticle={this.changeArticle}
                                    backToSave={this.backToSave}
                                    getParent={getParent}
                                    checkRights={checkRights}
                                    newFiles={newFiles}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
    };

    formData = new FormData();

    getFormData() {
        return this.formData;
    }

    uploadMainFile({ name, files }) {
        return new Promise((resolve) => {
            const [file] = files;

            this.formData.set(name, file.object);

            this.setState((state) => {
                const newState = { ...state };
                const newFiles = { ...newState.newFiles };

                newFiles[name] = file;

                newState.newFiles = newFiles;

                return newState;
            }, resolve);
        });
    }

    handlerFile = new File({});

    checkChange() {
        const { article, articleSave } = this.state;
        const fields = {};

        if (articleSave.content !== article.content) {
            fields.content = article.content;
        }

        return { isChange: Object.keys(fields).length > 0, fields };
    }

    updateArticle() {
        const { article } = this.state;
        const { isChange, fields } = this.checkChange();

        return new Promise((resolve) => {
            if (!isChange) {
                resolve();
            } else {
                this.formData.set('id', article._id);
                this.formData.set('fields', JSON.stringify(fields));

                axios
                    .patch(`${process.env.REACT_APP_API}/article`, this.formData, {
                        headers: getHeaders(),
                    })
                    .then(
                        (res) => {
                            const { success } = res.data;

                            if (success) {
                                setNotification({ notification: 'success-update-article' });

                                this.clearFiles();
                                this.getArticle();
                            } else {
                                handlerErrorRequest(res);
                            }

                            resolve();
                        },
                        () => null,
                    );
            }
        });
    }

    createArticle() {
        const { article } = this.state;
        const { name = 'blog' } = this.props;
        const articleResult = {};
        const info = this.getInfo();

        info.createFields.forEach((prop) => {
            articleResult[prop] = article[prop];
        });

        this.formData.set('article', JSON.stringify(articleResult));
        this.formData.set('date', new Date().toString());
        this.formData.set('type', name);

        return new Promise((resolve) => {
            axios
                .post(`${process.env.REACT_APP_API}/article`, this.formData, {
                    headers: getHeaders(),
                })
                .then(
                    (res) => {
                        const { success, data } = res.data;

                        if (success) {
                            const { _id } = data;

                            setNotification({ notification: 'success-create-article' });

                            changePage({
                                href: getPageLink({
                                    name: `content-${name}-main`,
                                }),
                            });

                            this.clearFiles();
                            this.getArticle(_id);
                        } else {
                            const { message } = data;

                            if (message === 'Section already create') {
                                setNotification({
                                    notification: 'article-section-already-create',
                                });

                                dispatcher({ type: 'articleErrors', data: ['newSection'] });

                                changePage({
                                    href: getPageLink({
                                        name: `content-${name}-inner-main`,
                                        ids: { 3: 'new' },
                                    }),
                                });
                            }

                            handlerErrorRequest(res);
                        }

                        resolve();
                    },
                    () => null,
                );
        });
    }

    clearFiles() {
        this.formData = new FormData();

        this.setState({ newFiles: {} });
    }

    publicateArticle({ detail: { handlerLoading } }) {
        const { checkNew } = this.props;

        if (checkNew()) {
            this.createArticle().then(
                () => {
                    handlerLoading(null);
                },
                () => null,
            );
        } else {
            this.updateArticle().then(
                () => {
                    handlerLoading(null);
                },
                () => null,
            );
        }
    }

    setArticle({ article }) {
        const { setInfoHead } = this.props;

        return new Promise((resolve) => {
            this.setState(
                {
                    article,
                    articleSave: JSON.parse(JSON.stringify(article)),
                },
                () => {
                    setInfoHead({ key: 'article', value: article });

                    if (!this.state.isInit) {
                        setTimeout(() => {
                            this.setState({ isInit: true });
                        }, 300);
                    }

                    resolve();
                },
            );
        });
    }

    changeArticle({ action, name, value }) {
        const { setInfoHead, checkRights } = this.props;

        return new Promise((resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };

                    if (action === 'change' && checkRights()) {
                        const article = JSON.parse(JSON.stringify(newState.article));

                        article[name] = value;

                        if (name === 'type' && article.type !== 'in-future') {
                            article.dateOfPublication = null;
                            article.timeOfPublication = null;
                        }

                        if (name === 'section' && value === 'vacancies') {
                            article.category = null;
                        }

                        newState.article = article;
                    }

                    return newState;
                },
                () => {
                    if (name === 'section') {
                        setInfoHead({ key: 'article', value: this.state.article });
                    }
                    resolve();
                },
            );
        });
    }

    getInfo() {
        const { name } = this.props;

        const fields = ['title', 'place', 'viewType', 'content'];

        if (name === 'info') {
            return {
                req: getArticles.bind(this, 'info'),
                createFields: [...fields, 'section', 'newSection'],
            };
        }

        if (name === 'announce') {
            return {
                req: getArticles.bind(this, 'announce'),
                createFields: [...fields, 'section', 'viewType'],
            };
        }

        if (name === 'legal') {
            return {
                req: getArticles.bind(this, 'legal'),
                createFields: [...fields],
            };
        }

        return {
            req: getArticles.bind(this, 'blog'),
            createFields: [...fields, 'section', 'smiLink'],
        };
    }

    getArticle(id) {
        const { levels } = this.props;
        const idOfArticle = id || levels[3];
        const info = this.getInfo();

        info.req({ id: idOfArticle, params: [{ key: 'isPrivate', value: true }] }).then(
            ({ article }) => {
                this.setArticle({ article });
            },
            this.handlerErrorGetModel,
        );
    }

    componentDidMount() {
        this.getArticle();

        document.addEventListener('publicateArticle', this.publicateArticle);
    }

    componentWillUnmount() {
        const { setInfoHead } = this.props;

        document.removeEventListener('publicateArticle', this.publicateArticle);

        setInfoHead({ key: 'article', value: undefined });
    }

    render() {
        const { isInit } = this.state;
        let { heightPage = 0 } = this.state;
        const { name } = this.props;

        if (!heightPage || !isInit) {
            heightPage = 500;
        }

        return (
            <div ref={this.parent} className={`widget ${isInit ? '_ready' : ''}`}>
                <div ref={this.parent} className="widget__content _full _scroll">
                    <Animate className="widget__loader _loader" isShow={!isInit}>
                        <i className="widget__loaderItem _loaderItem">
                            <Loader className="_main" />
                        </i>
                    </Animate>
                    <div className="widget__contentBox">
                        {isInit && (
                            <Pages
                                classNamePage="widget__page _deep2 _full"
                                filter={(page) =>
                                    page.parentName === `content-${name}-inner` && page.level === 4
                                }
                                pages={this.pages}
                                context={this}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        serverData: state.serverData,
        typeOrganization: state.typeOrganization,
    };
}

export default connect(mapStateToProps)(ContentBlogInner);

ContentBlogInner.propTypes = {
    levels: PropTypes.array,
    setInfoHead: PropTypes.func,
    getParent: PropTypes.func,
    checkNew: PropTypes.func,
    serverData: PropTypes.object,
    typeOrganization: PropTypes.string,
    checkRights: PropTypes.func,
    name: PropTypes.string,
};
